import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Container,
  FeatureImage,
  FeatureImageHero,
  FeatureImageHeroMobile,
  H2,
  Paragraph,
  TwoColumnContainer,
  ThreeColumnGrid
} from "../components/styled/components"
import Fade from "react-reveal/Fade"
import { Helmet } from "react-helmet"
import { useMediaQuery } from "react-responsive"

import wh_logo from "../images/wellhealth-logo-white.png"
import contact_tracing_icon from "../images/contact_tracing.svg"
import iv_icon from "../images/IV_Icon.svg"
import iv_icon_white from "../images/IV_Icon_White.svg"
import vaccine_icon from "../images/Vaccine_Icon.svg"
import vaccine_icon_white from "../images/Vaccine_Icon_White.svg"
import testing_icon from "../images/Testing_Icon.svg"
import testing_icon_white from "../images/Testing_Icon_White.svg"
import occupational_icon from "../images/Occupational_Icon.svg"
import occupational_icon_white from "../images/Occupational_Icon_White.svg"
import lab_icon from "../images/Lab_Icon.svg"
import lab_icon_white from "../images/Lab_Icon_White.svg"
import telemedicine_icon from "../images/Telemedicine_Icon.svg"
import telemedicine_icon_white from "../images/Telemedicine_Icon_White.svg"
import hayee from "../images/hayee.png"
import amir from "../images/amir.png"
import maan from "../images/maan.png"
import ousamah from "../images/ousamah.png"
import lyndsey from "../images/lyndsey.png"
import nys_doh from "../images/NYS_DOH_black.jpg"
import lowes from "../images/lowes.png"
import tdem from "../images/tdem.png"
import kdhe from "../images/kdhe.png"
import reno_airport from "../images/reno_airport.jpg"
import pods from "../images/pods.png"
import maricopa from "../images/maricopa.png"
import dhec from "../images/dhec.jpg"
import quiktrip from "../images/quiktrip.png"
import tolleson from "../images/tolleson.png"
import arts_academy from "../images/arts_academy.png"
import boys_girls_clubs from "../images/boys_girls_clubs.png"
import petrocap from "../images/petrocap.png"
import unt from "../images/unt.png"
import ware2go from "../images/ware2go.jpeg"
import johnson_county from "../images/johnson_county.png"
import navarro_college from "../images/navarro_college.png"
import texas_womans from "../images/texas_womans.png"
import austin_college from "../images/austin_college.jpg"

const IndexPage = ({ data }) => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })

  return (
    <Layout>
      <SEO title="Advanced Healthcare and COVID-19 Pandemic Response" />

      <Helmet>
        <meta name="google-site-verification" content="jidqWq9Ez51VjYi3HdVQC8D-mhHMp0kX1HjQHUwxxiU" />
        <script type="application/ld+json">
        {`{
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "https://www.wellhealth.studio",
          "logo": "${data.logo.childImageSharp.fixed.src}"
        }`}
        </script>
      </Helmet>

      {isTabletOrMobile ?
      <FeatureImageHeroMobile>
        <H2 textAlign="center" style={{ margin: "0 auto", fontFamily: "Gotham" }}>
          Complete Covid Care by WellHealth
        </H2>
        <H2 textAlign="center">
          Over 1 Million Tested
        </H2>
        <Img fluid={data.contentfulPage.heroImage.fluid} alt={data.contentfulPage.heroImage.title} />
      </FeatureImageHeroMobile> :
      <FeatureImageHero>
        <section>
          <img className="wh_logo" src={wh_logo} alt="WellHealth logo" />
          <ThreeColumnGrid className="service-icons" style={{ width: "75%", margin: "0 auto", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr", gridColumnGap: "30px" }}>
            <img src={iv_icon_white} alt="IV Therapy" />
            <img src={vaccine_icon_white} alt="Vaccine Services" />
            <img src={testing_icon_white} alt="COVID-19 Testing" />
            <img src={telemedicine_icon_white} alt="Telemedicine" />
            <img src={lab_icon_white} alt="Laboratory Services" />
            <img src={occupational_icon_white} alt="Occupational Health" />
          </ThreeColumnGrid>
          <H2 fontSize="32px" textAlign="center" style={{ margin: "0 auto", fontFamily: "Gotham" }}>
            Complete Covid Care by WellHealth
          </H2>
          <H2 fontSize="40px" textAlign="center">
            Over 1 Million Tested
          </H2>
        </section>
        <FeatureImage>
          <Img fluid={data.contentfulPage.heroImage.fluid} alt={data.contentfulPage.heroImage.title} />
        </FeatureImage>
      </FeatureImageHero>}

      <Container
        textAlign="center"
        css={{ marginBottom: "100px", paddingTop: "40px" }}
      >
        <Fade bottom>
          <H2 mt={5} fontSize="52px" textAlign="left">
            Services
          </H2>
          <ThreeColumnGrid style={{ minWidth: "100%", gridTemplateColumns: "9fr 1fr 12fr", gridColumnGap: 0 }}>
            <div>
              <H2 fontSize="42px">COVID-19</H2>
              <Link to="/covid-19/">View more details</Link>
              <ThreeColumnGrid style={{ gridColumnGap: "50px" }}>
                <div>
                  <img src={testing_icon} alt="COVID-19 Testing" />
                  <div className="service-name">
                    <span>COVID-19 Testing</span>
                  </div>
                </div>
                <div>
                  <img src={contact_tracing_icon} alt="Contact Tracing" />
                  <div className="service-name">
                    <span>Contact Tracing</span>
                  </div>
                </div>
                <div>
                  <img src={vaccine_icon} alt="Vaccine Services" />
                  <div className="service-name">
                    <span>Vaccine Services</span>
                  </div>
                </div>
              </ThreeColumnGrid>
            </div>
            <div style={{ margin: "auto", borderLeft: "2px solid #122A47", height: "100%", width: 0 }}></div>
            <div>
              <H2 fontSize="42px">Health Services</H2>
              <Link to="/services/">View more details</Link>
              <ThreeColumnGrid style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr", gridColumnGap: "50px" }}>
                <div>
                  <img src={occupational_icon} alt="Occupational Health" />
                  <div className="service-name">
                    <span>Well to Life Occupational Health</span>
                  </div>
                </div>
                <div>
                  <img src={lab_icon} alt="Laboratory Services" />
                  <div className="service-name">
                    <span>Laboratory Services</span>
                  </div>
                </div>
                <div>
                  <img src={iv_icon} alt="IV Infusion" />
                  <div className="service-name">
                    <span>IV Infusion</span>
                  </div>
                </div>
                <div>
                  <img src={telemedicine_icon} alt="Telemedicine" />
                  <div className="service-name">
                    <span>Telemedicine</span>
                  </div>
                </div>
              </ThreeColumnGrid>
            </div>
          </ThreeColumnGrid>
        </Fade>
      </Container>
      
      {data.contentfulPage.section1 && 
      <TwoColumnContainer>
        <Fade bottom>
          <div>
            <H2 fontSize="52px">{data.contentfulPage.section1.sectionHeader}</H2>
            {data.contentfulPage.section1.sectionLink &&
            <Link to={data.contentfulPage.section1.sectionLink}>
              View more details
            </Link>}
            <Paragraph pt={1} pb={3}>
              {data.contentfulPage.section1.sectionDescription.sectionDescription}
            </Paragraph>
          </div>
          <Img fluid={data.contentfulPage.section1.sectionImage.fluid} alt={data.contentfulPage.section1.sectionImage.title} />
        </Fade>
      </TwoColumnContainer>}

      {data.contentfulPage.section2 && 
      <TwoColumnContainer>
        <Fade bottom>
          <div>
            <H2 fontSize="52px">{data.contentfulPage.section2.sectionHeader}</H2>
            {data.contentfulPage.section2.sectionLink &&
            <Link to={data.contentfulPage.section2.sectionLink}>
              View more details
            </Link>}
            <Paragraph pt={1} pb={3}>
              {data.contentfulPage.section2.sectionDescription.sectionDescription}
            </Paragraph>
          </div>
          <Img fluid={data.contentfulPage.section2.sectionImage.fluid} alt={data.contentfulPage.section2.sectionImage.title} />
        </Fade>
      </TwoColumnContainer>}

      {data.contentfulPage.section3 && 
      <TwoColumnContainer>
        <Fade bottom>
          <div>
            <H2 fontSize="52px">{data.contentfulPage.section3.sectionHeader}</H2>
            {data.contentfulPage.section3.sectionLink &&
            <Link to={data.contentfulPage.section3.sectionLink}>
              View more details
            </Link>}
            <Paragraph pt={1} pb={3}>
              {data.contentfulPage.section3.sectionDescription.sectionDescription}
            </Paragraph>
          </div>
          <Img fluid={data.contentfulPage.section3.sectionImage.fluid} alt={data.contentfulPage.section3.sectionImage.title} />
        </Fade>
      </TwoColumnContainer>}

      {data.contentfulPage.section4 && 
      <TwoColumnContainer>
        <Fade bottom>
          <div>
            <H2 fontSize="52px">{data.contentfulPage.section4.sectionHeader}</H2>
            {data.contentfulPage.section4.sectionLink &&
            <Link to={data.contentfulPage.section4.sectionLink}>
              View more details
            </Link>}
            <Paragraph pt={1} pb={3}>
              {data.contentfulPage.section4.sectionDescription.sectionDescription}
            </Paragraph>
          </div>
          <Img fluid={data.contentfulPage.section4.sectionImage.fluid} alt={data.contentfulPage.section4.sectionImage.title} />
        </Fade>
      </TwoColumnContainer>}

      {data.contentfulPage.section5 && 
      <TwoColumnContainer>
        <Fade bottom>
          <div>
            <H2 fontSize="52px">{data.contentfulPage.section5.sectionHeader}</H2>
            {data.contentfulPage.section5.sectionLink &&
            <Link to={data.contentfulPage.section5.sectionLink}>
              View more details
            </Link>}
            <Paragraph pt={1} pb={3}>
              {data.contentfulPage.section5.sectionDescription.sectionDescription}
            </Paragraph>
          </div>
          <Img fluid={data.contentfulPage.section5.sectionImage.fluid} alt={data.contentfulPage.section5.sectionImage.title} />
        </Fade>
      </TwoColumnContainer>}

      {data.contentfulPage.section6 && 
      <TwoColumnContainer>
        <Fade bottom>
          <div>
            <H2 fontSize="52px">{data.contentfulPage.section6.sectionHeader}</H2>
            {data.contentfulPage.section6.sectionLink &&
            <Link to={data.contentfulPage.section6.sectionLink}>
              View more details
            </Link>}
            <Paragraph pt={1} pb={3}>
              {data.contentfulPage.section6.sectionDescription.sectionDescription}
            </Paragraph>
          </div>
          <Img fluid={data.contentfulPage.section6.sectionImage.fluid} alt={data.contentfulPage.section6.sectionImage.title} />
        </Fade>
      </TwoColumnContainer>}

      {data.contentfulPage.section7 && 
      <TwoColumnContainer>
        <Fade bottom>
          <div>
            <H2 fontSize="52px">{data.contentfulPage.section7.sectionHeader}</H2>
            {data.contentfulPage.section7.sectionLink &&
            <Link to={data.contentfulPage.section7.sectionLink}>
              View more details
            </Link>}
            <Paragraph pt={1} pb={3}>
              {data.contentfulPage.section7.sectionDescription.sectionDescription}
            </Paragraph>
          </div>
          <Img fluid={data.contentfulPage.section7.sectionImage.fluid} alt={data.contentfulPage.section7.sectionImage.title} />
        </Fade>
      </TwoColumnContainer>}

      {data.contentfulPage.section8 && 
      <TwoColumnContainer>
        <Fade bottom>
          <div>
            <H2 fontSize="52px">{data.contentfulPage.section8.sectionHeader}</H2>
            {data.contentfulPage.section8.sectionLink &&
            <Link to={data.contentfulPage.section8.sectionLink}>
              View more details
            </Link>}
            <Paragraph pt={1} pb={3}>
              {data.contentfulPage.section8.sectionDescription.sectionDescription}
            </Paragraph>
          </div>
          <Img fluid={data.contentfulPage.section8.sectionImage.fluid} alt={data.contentfulPage.section8.sectionImage.title} />
        </Fade>
      </TwoColumnContainer>}

      {data.contentfulPage.section9 && 
      <TwoColumnContainer>
        <Fade bottom>
          <div>
            <H2 fontSize="52px">{data.contentfulPage.section9.sectionHeader}</H2>
            {data.contentfulPage.section9.sectionLink &&
            <Link to={data.contentfulPage.section9.sectionLink}>
              View more details
            </Link>}
            <Paragraph pt={1} pb={3}>
              {data.contentfulPage.section9.sectionDescription.sectionDescription}
            </Paragraph>
          </div>
          <Img fluid={data.contentfulPage.section9.sectionImage.fluid} alt={data.contentfulPage.section9.sectionImage.title} />
        </Fade>
      </TwoColumnContainer>}

      {data.contentfulPage.section10 && 
      <TwoColumnContainer>
        <Fade bottom>
          <div>
            <H2 fontSize="52px">{data.contentfulPage.section10.sectionHeader}</H2>
            {data.contentfulPage.section10.sectionLink &&
            <Link to={data.contentfulPage.section10.sectionLink}>
              View more details
            </Link>}
            <Paragraph pt={1} pb={3}>
              {data.contentfulPage.section10.sectionDescription.sectionDescription}
            </Paragraph>
          </div>
          <Img fluid={data.contentfulPage.section10.sectionImage.fluid} alt={data.contentfulPage.section10.sectionImage.title} />
        </Fade>
      </TwoColumnContainer>}

      <Container
        textAlign="center"
        css={{ marginBottom: "100px", paddingTop: "40px" }}
      >
        <Fade bottom>
          <H2 mt={5} fontSize="52px" textAlign="left">
            Meet Our Team
          </H2>
          <ThreeColumnGrid style={{ minWidth: "100%", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr", gridColumnGap: 0 }}>
            <div className="team-member">
              <img src={hayee} alt="Dr Hayee" />
              <div className="name">
                <span>Dr. Abdul-Ahad Hayee</span>
              </div>
              <div className="title">
                <span>Chief Executive Officer</span>
              </div>
            </div>
            <div className="team-member">
              <img src={amir} alt="Amir" />
              <div className="name">
                <span>Amir Kuzbari</span>
              </div>
              <div className="title">
                <span>Chief Revenue Officer</span>
              </div>
            </div>
            <div className="team-member">
              <img src={maan} alt="Maan" />
              <div className="name">
                <span>Maan Abdelkader</span>
              </div>
              <div className="title">
                <span>Chief Financial Officer</span>
              </div>
            </div>
            <div className="team-member">
              <img src={lyndsey} alt="Lyndsey" />
              <div className="name">
                <span>Lyndsey Normand</span>
              </div>
              <div className="title">
                <span>VP of Business Affairs and Operations</span>
              </div>
            </div>
            <div className="team-member" style={{ paddingTop: "3.5%" }}>
              <img src={ousamah} alt="Ousamah" />
              <div className="name">
                <span>Ousamah Farra</span>
              </div>
              <div className="title">
                <span>Manager of Business Affairs</span>
              </div>
            </div>
          </ThreeColumnGrid>
        </Fade>
      </Container>

      <Container
        textAlign="center"
        css={{ marginBottom: "100px", paddingTop: "40px" }}
      >
        <Fade bottom>
          <H2 mt={5} fontSize="52px" textAlign="left">
            Our Partnerships
          </H2>
          <ThreeColumnGrid style={{ minWidth: "100%", gridTemplateColumns: "1fr 1fr 1fr", gridColumnGap: 0 }}>
            <img className="partner" src={nys_doh} alt="New York Department of Health" />
            <img className="partner" src={tdem} alt="TDEM" />
            <img className="partner" src={kdhe} alt="KDHE" />
          </ThreeColumnGrid>
          <ThreeColumnGrid style={{ minWidth: "100%", gridTemplateColumns: "1fr 1fr 1fr 1fr", gridColumnGap: 0 }}>
            <img className="partner" src={lowes} alt="Lowe's" />
            <img className="partner" src={reno_airport} alt="Reno Airport" />
            <img className="partner" src={pods} alt="Pods" />
            <img className="partner" src={maricopa} alt="Maricopa County" />
          </ThreeColumnGrid>
          <ThreeColumnGrid style={{ minWidth: "100%", gridTemplateColumns: "1fr 1fr 1fr 1fr", gridColumnGap: 0 }}>
            <img className="partner" src={dhec} alt="dhec" />
            <img className="partner" src={quiktrip} alt="QuikTrip" />
            <img className="partner" src={tolleson} alt="City of Tolleson" />
            <img className="partner" src={arts_academy} alt="Arts Academy" />
          </ThreeColumnGrid>
          <ThreeColumnGrid style={{ minWidth: "100%", gridTemplateColumns: "1fr 1fr 1fr 1fr", gridColumnGap: 0 }}>
            <img className="partner" src={boys_girls_clubs} alt="Boys and Girls Clubs" />
            <img className="partner" src={petrocap} alt="PetroCap" />
            <img className="partner" src={unt} alt="UNT" />
            <img className="partner" src={ware2go} alt="Ware2Go" />
          </ThreeColumnGrid>
          <ThreeColumnGrid style={{ minWidth: "100%", gridTemplateColumns: "1fr 1fr 1fr 1fr", gridColumnGap: 0 }}>
            <img className="partner" src={johnson_county} alt="Johnson County" />
            <img className="partner" src={navarro_college} alt="Navarro College" />
            <img className="partner" src={texas_womans} alt="Texas Woman's" />
            <img className="partner" src={austin_college} alt="Austin College" />
          </ThreeColumnGrid>
        </Fade>
      </Container>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomePageQuery {
    contentfulPage(pageName: {eq: "Home"}) {
      heroImage {
        title
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      section1 {
        sectionHeader
        sectionDescription {
          sectionDescription
        }
        sectionImage {
          title
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        sectionLink
      }
      section2 {
        sectionHeader
        sectionDescription {
          sectionDescription
        }
        sectionImage {
          title
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        sectionLink
      }
      section3 {
        sectionHeader
        sectionDescription {
          sectionDescription
        }
        sectionImage {
          title
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        sectionLink
      }
      section4 {
        sectionHeader
        sectionDescription {
          sectionDescription
        }
        sectionImage {
          title
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        sectionLink
      }
      section5 {
        sectionHeader
        sectionDescription {
          sectionDescription
        }
        sectionImage {
          title
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        sectionLink
      }
      section6 {
        sectionHeader
        sectionDescription {
          sectionDescription
        }
        sectionImage {
          title
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        sectionLink
      }
    }
    logo: file(relativePath: { eq: "wellhealth-logo.png" }) {
      childImageSharp {
        fixed(width: 190, height: 32) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
